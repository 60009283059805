import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage, setIsHomeProcess, setMember } from "../../app/GlobalSlice";
import Navbar from "../../components/Navbar";

import Select from "react-select"
import { getBank, getKabupaten, getKecamatan, getProvinsi, updateProfile, updateRekening } from "./Api";
import { handleResponse200 } from "../../utils/HttpUtils";
import { notifyError, notifySuccess } from "../../utils/Notification";

import UploadPhotoDialog from "./UploadPhotoDialog";
import { isPhone } from "../../utils/PlatformHelper";

const ProfileRekening = () => {
  const dispatch = useDispatch()

  const { 
    member
  } = useSelector(state => state.global)

  const [form, setForm] = useState({
    noRek: '',
    atasNama: ''
  })

  const [bankOpts, setBankOpts] = useState({
    data: [],
    isLoad: false,
    selectedOpt: null
  })

  useEffect(() => {

    if (!member || !member.rekening) return;

    setForm({
      ...form,
      noRek: member.rekening.afNoRek,
      atasNama: member.rekening.afAtasNama  
    })

    setBankOpts({ 
      ...bankOpts, 
      selectedOpt: { 
        label: member.rekening.afNamaBank,
        value: member.rekening.afIdBank
      } 
    })

  }, [member])

  function onSelectBank() {
    setBankOpts({ ...bankOpts, isLoad: true })

    getBank()
      .then(res => {
        if (res.success) {
          setBankOpts({ ...bankOpts, data: res.payload.map(r => ({ label: r.namaBank, value: r.idBank })), isLoad: false })
        }
        else {

        }
      })
      .catch(error => {
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
          }
          else {
          }
        }
      })
  }

  function onSave() {
    dispatch(setIsHomeProcess(true))

    const payload = {
      idBank: bankOpts.selectedOpt ? bankOpts.selectedOpt.value : null,
      noRek: form.noRek,
      atasNama: form.atasNama
    }

    updateRekening(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            notifySuccess('Berhasil update data')
            dispatch(setMember({
              ...member,
              rekening: {
                ...member.rekening,
                afAtasNama: form.atasNama,
                afIdBank: bankOpts.selectedOpt ? bankOpts.selectedOpt.value : null,
                afNamaBank: bankOpts.selectedOpt ? bankOpts.selectedOpt.label : null,
                afNoRek: form.noRek
              }
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
          }),
          onUnAuth: error => {
              
          },
          onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
          })
        })
      })
      .catch(error => {})
      .finally(() => {
        dispatch(setIsHomeProcess(false))
      })

  }

  return (
    <div className="pb-10">
      <div className="px-3 py-3">
        <div
          className="uppercase font-bold"
        >
          Atur Data Rekening
        </div>
        <div
          className="italic"
          style={{
            fontSize: '14px'
          }}
        >
          Atur data rekening anda
        </div>
      </div>
      <div className="px-8 space-y-3">
        <div className="space-y-1">
          <div>Bank</div>
          <div>
            <Select
              value={bankOpts.selectedOpt}
              options={bankOpts.data}
              isLoading={bankOpts.isLoad}
              menuPlacement="auto"
              className="w-full border border-gray-200 rounded-md"
              onFocus={onSelectBank}
              onChange={(value) => {
                setBankOpts({ ...bankOpts, selectedOpt: value })
              }}
            />
          </div>
        </div>
        <div className="space-y-1">
          <div>No. Rekening</div>
          <div>
            <input 
              className="w-full border border-gray-300 rounded-md p-1.5 outline-green-500"
              value={form.noRek}
              onChange={e => setForm({ ...form, noRek: e.target.value })}
            />
          </div>
        </div>
        <div className="space-y-1">
          <div>Atas Nama</div>
          <div>
            <input 
              className="w-full border border-gray-300 rounded-md p-1.5 outline-green-500"
              value={form.atasNama}
              onChange={e => setForm({ ...form, atasNama: e.target.value })}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="p-1 px-8 bg-blue-500 text-white rounded-md hover:bg-blue-400 hover:shadow-lg"
            onClick={onSave}
          > 
            Simpan
          </button>
        </div>
      </div>
    </div>
  )

}

const ProfilePage = () => {
  const dispatch = useDispatch()

  const { 
    member,
    screenSize
  } = useSelector(state => state.global)

  const [form, setForm] = useState({
    idAffiliater: '',
    namaAffiliater: '',
    email: '',
    noHp: '',
    alamatLengkap: ''
  })

  const [provOpts, setProvOpts] = useState({
    data: [],
    isLoad: false,
    selectedOpt: null
  })

  const [kabOpts, setKabOpts] = useState({
    data: [],
    isLoad: false,
    selectedOpt: null
  })

  const [kecOpts, setKecOpts] = useState({
    data: [],
    isLoad: false,
    selectedOpt: null
  })

  const [isUploadPhotoOpen, setIsUploadPhotoOpen] = useState(false)

  useEffect(() => {
    document.body.style.backgroundColor =  isPhone(screenSize.width) ? 'white' : '#232745';

    dispatch(setActivePage('profile'))
  }, [screenSize.width])

  useEffect(() => {
    if (!member) return;

    setForm({
      ...form,
      idAffiliater: member.idAffiliater,
      namaAffiliater: member.namaAffiliater,
      email: member.email,
      noHp: member.noHp,
      alamatLengkap: member.address
    })

    if (member.provinsi) {
      setProvOpts({ 
        ...provOpts, 
        selectedOpt: { 
          label: member.provinsi.label, 
          value: member.provinsi.id 
        } 
      })
    }

    if (member.kabupaten) {
      setKabOpts({
        ...kabOpts,
        selectedOpt: {
          label: member.kabupaten.label,
          value: member.kabupaten.id
        }
      })
    }

    if (member.kecamatan) {
      setKecOpts({
        ...kecOpts,
        selectedOpt: {
          label: member.kecamatan.label,
          value: member.kecamatan.id
        }
      })
    }

  }, [member])

  function onSelectProv() {
    setProvOpts({ ...provOpts, isLoad: true })

    getProvinsi()
      .then(res => {
        if (res.success) {
          setProvOpts({ ...provOpts, data: res.payload.map(r => ({ label: r.label, value: r.id })), isLoad: false })
        }
        else {

        }
      })
      .catch(error => {
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
          }
          else {
          }
        }
      })
  }

  function onSelectKab() {
    setKabOpts({ ...kabOpts, isLoad: true })

    getKabupaten(provOpts.selectedOpt.value)
      .then(res => {
        if (res.success) {
          setKabOpts({ ...kabOpts, data: res.payload.map(r => ({ label: r.label, value: r.id })), isLoad: false })
        }
        else {

        }
      })
      .catch(error => {
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
          }
          else {
          }
        }
      })
  }

  function onSelectKec() {
    setKecOpts({ ...kecOpts, isLoad: true })

    getKecamatan(kabOpts.selectedOpt.value)
      .then(res => {
        if (res.success) {
          setKecOpts({ ...kecOpts, data: res.payload.map(r => ({ label: r.label, value: r.id })), isLoad: false })
        }
        else {

        }
      })
      .catch(error => {
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
          }
          else {
          }
        }
      })
  }

  function onSaveProfile() {
    dispatch(setIsHomeProcess(true))

    const payload = {
      namaAffiliater: form.namaAffiliater,
      noHp: form.noHp,
      idProvinsi: provOpts.selectedOpt ? provOpts.selectedOpt.value : null,
      idKabupaten: kabOpts.selectedOpt ? kabOpts.selectedOpt.value : null,
      idKecamatan: kecOpts.selectedOpt ? kecOpts.selectedOpt.value : null,
      alamatLengkap: form.alamatLengkap
    }

    updateProfile(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            notifySuccess('Berhasil update data')
            dispatch(setMember({
              ...member,
              namaAffiliater: form.namaAffiliater,
              noHp: form.noHp,
              provinsi: provOpts.selectedOpt ? { label: provOpts.selectedOpt.label, id: provOpts.selectedOpt.value } : null,
              kabupaten: kabOpts.selectedOpt ? { label: kabOpts.selectedOpt.label, id: kabOpts.selectedOpt.value } : null,
              kecamatan: kecOpts.selectedOpt ? { label: kecOpts.selectedOpt.label, id: kecOpts.selectedOpt.value } : null,
              address: form.alamatLengkap
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
          }),
          onUnAuth: error => {
              
          },
          onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
          })
        })
      })
      .catch(error => {})
      .finally(() => {
        dispatch(setIsHomeProcess(false))
      })

  }

  return (
    <>
    {isUploadPhotoOpen && (
      <UploadPhotoDialog 
        onClose={() => setIsUploadPhotoOpen(false)}
        onFileSelected={file => null}
      />
    )}
    <div
      className="flex justify-center"
    >
      <div
        className={
          isPhone(screenSize.width) ? " w-full space-y-3  " : "w-[800px] pt-10 space-y-3"
        }
      >

        <Navbar />

        <div
          className="bg-white rounded-lg divide-y divide-gray-200 relative"
        >
          <div
            className="p-3"
          >
            <div
              className="uppercase font-bold"
            >
              Atur Profil
            </div>
            <div
              className="italic"
              style={{
                fontSize: '14px'
              }}
            >
              Atur profil anda demi kemudahan segala bentuk operasional
            </div>
            <div
              className="py-3 px-5 space-y-3"
              style={{
                fontSize: '15px'
              }}
            >
              <div className="space-y-1">
                <div>ID</div>
                <div>
                  <input 
                    className="w-full border bg-gray-200 border-gray-300 rounded-md p-1.5 outline-green-500"
                    value={form.idAffiliater}
                    readOnly
                  />
                </div>
              </div>
              <div className="space-y-1">
                <div>Nama</div>
                <div>
                  <input 
                    className="w-full border border-gray-300 rounded-md p-1.5 outline-green-500"
                    value={form.namaAffiliater}
                    onChange={e => setForm({ ...form, namaAffiliater: e.target.value })}
                  />
                </div>
              </div>
              <div className="space-y-1">
                <div>Email</div>
                <div>
                  <input 
                    className="w-full border border-gray-300 bg-gray-200 rounded-md p-1.5 outline-green-500"
                    value={form.email}
                    readOnly
                  />
                </div>
              </div>
              <div className="space-y-1">
                <div>No. HP</div>
                <div className="relative">
                  <input 
                    className="w-full pl-12 border border-gray-300 rounded-md p-1.5 outline-green-500"
                    value={form.noHp}
                    onChange={e => setForm({ ...form,  })}
                  />
                  <div 
                    class="flex absolute w-10 inset-y-0 left-0 items-center justify-center border-r border-gray-300"
                    style={{
                      fontSize: '14px'
                    }}
                  >
                    +62
                  </div>
                </div>
              </div>
              <div className="space-y-1">
                <div>Provinsi</div>
                <div>
                  <Select
                    value={provOpts.selectedOpt}
                    options={provOpts.data}
                    isLoading={provOpts.isLoad}
                    menuPlacement="auto"
                    className="w-full border border-gray-200 rounded-md"
                    onFocus={onSelectProv}
                    onChange={(value) => {
                      setProvOpts({ ...provOpts, selectedOpt: value })
                      setKabOpts({ ...kabOpts, selectedOpt: null })
                      setKecOpts({ ...kecOpts, selectedOpt: null })
                    }}
                  />
                </div>
              </div>
              <div className="space-y-1">
                <div>Kota/Kabupaten</div>
                <div>
                  <Select
                    value={kabOpts.selectedOpt}
                    options={kabOpts.data}
                    isLoading={kabOpts.isLoad}
                    menuPlacement="auto"
                    className="w-full border border-gray-200 rounded-md"
                    onFocus={onSelectKab}
                    onChange={(value) => {
                      setKabOpts({ ...kabOpts, selectedOpt: value })
                      setKecOpts({ ...kecOpts, selectedOpt: null })
                    }}
                  />
                </div>
              </div>
              <div className="space-y-1">
                <div>Kecamatan</div>
                <div>
                  <Select
                    value={kecOpts.selectedOpt}
                    options={kecOpts.data}
                    isLoading={kecOpts.isLoad}
                    menuPlacement="auto"
                    className="w-full border border-gray-200 rounded-md"
                    onFocus={onSelectKec}
                    onChange={(value) => {
                      setKecOpts({ ...kecOpts, selectedOpt: value })
                    }}
                  />
                </div>
              </div>
              <div className="space-y-1">
                <div>Alamat</div>
                <div>
                  <textarea
                    className="border border-gray-300 outline-green-500 p-1.5 w-full rounded-md"
                    rows={5}
                    value={form.alamatLengkap}
                    onChange={e => setForm({ ...form, alamatLengkap: e.target.value })}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  className="p-1 px-8 bg-blue-500 text-white rounded-md hover:bg-blue-400 hover:shadow-lg"
                  onClick={onSaveProfile}
                > 
                  Simpan
                </button>
              </div>
            </div>
            <div>

            </div>
          </div>
          <div>
            <ProfileRekening />
          </div>
        </div>
      </div>
      
    </div>
    </>
  )

}

export default ProfilePage;