import axios from "axios"

export const logout = async () => {
  const finalUrl = window._HOST + "affiliater/logout"
  const response = await axios.post(finalUrl, {}, { withCredentials: true })
  return response.data
}

export const getSystemProfile = async () => {
  const finalUrl = window._HOST + "public/system-profile"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getSessionProfile = async () => {
  const finalUrl = window._HOST + "affiliater/profile"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const changePassword = async (payload) => {
  const finalUrl =
    window._HOST + "affiliater/password"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}