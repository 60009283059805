import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";
import { RiLoader5Fill } from "react-icons/ri";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { handleResponse200 } from "../../utils/HttpUtils";
import { notifyError } from "../../utils/Notification";
import { verifyAccount } from "./apis/Api";
import { useSelector } from "react-redux";

const MailVerificationPage = () => {

  const { id, token } = useParams()

  const { systemProfile, screenSize } = useSelector(state => state.global)
  
  const [isProcessing, setIsProcessing] = useState(false)

  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    setIsProcessing(false)

    verifyAccount(id, token)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setIsSuccess(true)
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
              
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {

      })
      .finally(() => setIsProcessing(false))

  }, [])

  return (
    <div
      className="w-screen h-screen"
      style={{
        backgroundColor: '#232745'
      }}
    >

      <div
        className="py-10 flex justify-center"
      >

        <div
          className="relative rounded-lg bg-white p-10"
          style={{
            width: '500px',
            height: '700px'
          }}
        >
          <div className="flex space-x-1 items-center justify-center">
            {systemProfile && (<img src={systemProfile.companyLogo + '?' + Date.now()} width={'150px'}/>)}
          </div>
          <div>
              {
                isProcessing ?
                  (<div
                    className="py-20 space-y-14"
                  >
                    <div
                      className="flex justify-center"
                    >
                      <RiLoader5Fill className="h-14 w-14 animate-spin"/>
                    </div>
                    <div
                      className="text-center"
                    >
                      <div>Sedang memverifikasi.</div> 
                      <div>Mohon tunggu beberapa saat.</div>
                    </div>

                  </div>)
                  :
                  isSuccess ?
                    (<div
                      className="py-20 space-y-14"
                    >
                      <div
                        className="flex justify-center"
                      >
                        <BsCheckCircleFill className="h-20 w-20 text-green-500"/>
                      </div>
                      <div
                        className="text-center"
                      >
                        <div>Email anda berhasil diverifikasi</div> 
                        <div>Silahkan klik tombol di bawah untuk login.</div>
                        <div
                          className="flex justify-center py-5"
                        >
                          <Link
                            to={'/login'}
                          >
                          <button
                            className="py-2 px-10 rounded-sm bg-blue-700 hover:bg-blue-500 text-white"
                            style={{
                              fontSize: '14px'
                            }}
                          >
                            Login
                          </button>
                          </Link>
                        </div>
                      </div>
  
                    </div>)
                    :
                    (<div
                      className="py-20 space-y-14"
                    >
                      <div
                        className="flex justify-center"
                      >
                        <AiFillCloseCircle className="h-20 w-20 text-red-500"/>
                      </div>
                      <div
                        className="text-center"
                      >
                        <div>Email gagal diverifikasi</div> 
                        <div>Kontak kami untuk informasi lebih lanjut</div>
                      </div>
  
                    </div>)
              }
          </div>
        </div>

      </div>

    </div>
  )

}

export default MailVerificationPage;