import { createSlice } from '@reduxjs/toolkit'
import { format } from 'date-fns'

export const pages = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    link: '/home'
  },
  {
    id: 'visitor',
    label: 'Kunjungan',
    link: '/home/visitor'
  },
  {
    id: 'participant',
    label: 'Pendaftar',
    link: '/home/participant'
  },
  {
    id: 'income',
    label: 'Income',
    link: '/home/income'
  },
  {
    id: 'profile',
    label: 'Profil',
    link: '/home/profile'
  },
]

const initialState = {
  member: null,
  activePage: 'dashboard',
  systemProfile: null,
  screenSize: {
    width: 0,
    height: 0
  },
  screenLoad: false,
  isHomeProcess: false,
  isLogoutDialogOpen: false,
  isProfileDialogOpen: false,
  isChangePassDialogOpen: false
}

export const GlobalSlice = createSlice({
  name: 'Global',
  initialState,
  reducers: {
    resetMember: state => {
      state.member = null
    },
    setMember: (state, { payload }) => {
      state.member = payload
    },
    setSystemProfile: (state, { payload }) => {
      state.systemProfile = payload
    },
    setScreenSize: (state, { payload }) => {
      state.screenSize = payload
    },
    setScreenLoad: (state, { payload }) => {
      state.screenLoad = payload
    },
    setIsHomeProcess: (state, { payload }) => {
      state.isHomeProcess = payload
    },
    setIsLogoutDialogOpen: (state, { payload }) => {
      state.isLogoutDialogOpen = payload
    },
    setIsProfileDialogOpen: (state, { payload }) => {
      state.isProfileDialogOpen = payload
    },
    setIsChangePasswordDialogOpen: (state, { payload }) => {
      state.isChangePassDialogOpen = payload
    },
    setActivePage: (state, { payload }) => {
      state.activePage = payload
    }
  }
})

export const {
  resetMember,
  setMember,
  setSystemProfile,
  setScreenSize,
  setScreenLoad,
  setIsHomeProcess,
  setIsLogoutDialogOpen,
  setIsProfileDialogOpen,
  setIsChangePasswordDialogOpen,
  setActivePage
} = GlobalSlice.actions

export default GlobalSlice.reducer