import { Dialog, Transition } from "@headlessui/react"
import { FaUserCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { GoSignOut } from "react-icons/go";
import { useState } from "react";
import { AiOutlineKey } from "react-icons/ai";
import { FiMenu } from "react-icons/fi"
import { pages, setIsChangePasswordDialogOpen, setIsLogoutDialogOpen } from "../app/GlobalSlice"
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { isPhone } from "../utils/PlatformHelper";

const Navbar = () => {
  const dispatch = useDispatch()
  
  const { 
    member, 
    activePage,
    screenSize
  } = useSelector(state => state.global);

  const [isProfileOpen, setIsProfilOpen] = useState(false)

  if (isPhone(screenSize.width)) {
    return (
      <div
      >
        <div
          className="relative flex items-center justify-between bg-white py-3 px-3 z-10"
        >
          <div>
            <Menu
              as={'div'}
              className=""
            >
              <Menu.Button
                className={"flex items-center"}
                style={{
                  fontSize: '14px'
                }}
              >
                <FiMenu className="h-6 w-6 mr-2"/>
              </Menu.Button>
              <Transition
                // as={DocumentFragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={"absolute left-0 mt-2 w-56 p-2 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-100"}
                  style={{
                    zIndex: '99',
                    fontSize: '14px'
                  }}
                >
                  {pages.map(p => (
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={p.link}
                        >
                          <button
                            className={`w-full p-2 uppercase rounded-md ${activePage === p.id ? 'bg-blue-500 text-white' : ''}`}
                          >
                            {p.label}
                          </button>
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                  
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div>
            <Menu
              as={'div'}
              className=""
            >
              <Menu.Button
                className={"flex items-center"}
                style={{
                  fontSize: '14px'
                }}
              >
                <FaUserCircle className="h-6 w-6 mr-2"/>
                Akun
              </Menu.Button>
              <Transition
                // as={DocumentFragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={"absolute right-0 mt-2 w-56 p-2 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-100"}
                  style={{
                    zIndex: '90'
                  }}
                >
                  <Menu.Item>
                    {({ active }) => (
                      <button 
                        className={`p-2 w-full flex items-center rounded-lg ${active && 'bg-blue-100'}`}
                        onClick={() => {
                          dispatch(setIsChangePasswordDialogOpen(true))
                        }}
                      >
                        <AiOutlineKey className="h-5 w-5 mr-2"/>
                      Ubah password
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button 
                        className={`p-2 w-full flex items-center rounded-lg ${active && 'bg-blue-100'}`}
                        onClick={() => {
                          dispatch(setIsLogoutDialogOpen(true))
                        }}
                      >
                        <GoSignOut className="h-5 w-5 mr-2"/>
                        Keluar
                      </button>
                    )}
                  </Menu.Item>
                  
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>  
      </div>  
    )
  }

  return (
    <div
    >
      <div
        className="relative flex items-center justify-between bg-white p-2 px-3 rounded-lg"
      >
        <div
          className="flex items-center space-x-2"
          style={{
            fontSize: '14px'
          }}
        >
          {pages.map(p => (
            <Link
              to={p.link}
            >
              <button
                className={`p-2 uppercase rounded-md ${activePage === p.id ? 'bg-blue-500 text-white' : 'border'}`}
              >
                {p.label}
              </button>
            </Link>
          ))}
        </div>
        <div>
          <Menu
            as={'div'}
            className=""
          >
            <Menu.Button
              className={"flex items-center"}
              style={{
                fontSize: '14px'
              }}
            >
              <FaUserCircle className="h-6 w-6 mr-2"/>
              Akun
            </Menu.Button>
            <Transition
              // as={DocumentFragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={"absolute right-0 mt-2 w-56 p-2 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-100"}
                style={{
                  zIndex: '90'
                }}
              >
                <Menu.Item>
                  {({ active }) => (
                    <button 
                      className={`p-2 w-full flex items-center rounded-lg ${active && 'bg-blue-100'}`}
                      onClick={() => {
                        dispatch(setIsChangePasswordDialogOpen(true))
                      }}
                    >
                      <AiOutlineKey className="h-5 w-5 mr-2"/>
                    Ubah password
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button 
                      className={`p-2 w-full flex items-center rounded-lg ${active && 'bg-blue-100'}`}
                      onClick={() => {
                        dispatch(setIsLogoutDialogOpen(true))
                      }}
                    >
                      <GoSignOut className="h-5 w-5 mr-2"/>
                      Keluar
                    </button>
                  )}
                </Menu.Item>
                
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

      </div>
    </div>
  )

}

export default Navbar;