import axios from "axios"

export const register = async (payload) => {
  const finalUrl =
      window._HOST + "affiliater/registration"

  const response =
      await axios.post(finalUrl, payload)

  return response.data
}

export const getProgram = async () => {
  const finalUrl =
      window._HOST + "public/program"

  const response =
      await axios.get(finalUrl)

  return response.data
}