import { useEffect, useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillEye } from "react-icons/ai";
import { BsCheckCircleFill, BsFillShieldLockFill } from "react-icons/bs";
import { RiLoader5Fill } from "react-icons/ri";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { handleResponse200 } from "../../utils/HttpUtils";
import { resetPassword } from "./apis/Api";

const ResetPasswordPage = () => {
  const { id, token } = useParams()

  const [form, setForm] = useState({
    password: '',
    passwordShown: false,
    passwordConfirmation: '',
    passwordConfirmationShown: false
  })

  const [isProcessing, setIsProcessing] = useState(false)

  const [isSuccess, setIsSuccess] = useState(null); //null, true or false

  function onResetPassword() {
    const errors = [];

    if (form.password === '') {
      errors.push('Password tidak boleh kosong')
    }

    if (form.passwordConfirmation === '') {
      errors.push('Konfirmasi tidak boleh kosong')
    }

    if (form.password !== form.passwordConfirmation) {
      errors.push('Password dan konfirmasi password tidak sama')
    }

    if (errors.length > 0) {
      return
    }

    const payload = {
      userId: id,
      token: token,
      newPassword: form.password
    }

    setIsProcessing(true)

    resetPassword(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setIsSuccess(true)
          },
          onRecovFailure: errors => errors.forEach(err => {
              setIsSuccess(false)
          }),
          onUnAuth: error => {
              
          },
          onTechnicalError: errors => errors.forEach(err => {
              setIsSuccess(false)
          })
        })
      })
      .catch(error => {})
      .finally(() => setIsProcessing(false))

  }

  return (
    <div
      className="w-screen h-screen"
      style={{
        backgroundColor: '#232745'
      }}
    >
      <div
        className="py-10 flex justify-center"
      >

        <div
          className="relative rounded-sm flex"
          style={{
            width: '1100px',
            height: '800px'
          }}
        >
          {isProcessing && (
            <div 
              className="absolute top-0 left-0 right-0 bottom-0 z-10"
            />
          )}
          <div
            className="flex-1 bg-transparent rounded-tl-2xl rounded-bl-2xl flex justify-center pt-16"
            style={{
              backgroundImage: 'url(https://skillacademy.com/assets/image/AuthBackground.jpg)',
              backgroundSize: 'cover'
            }}
          >
            <div
              className="space-y-12"
            >
              <div className="flex space-x-1 items-center justify-center pt-5">
                <img src="/images/ahs-logo.png" width={'90px'}/>
                <label 
                  className="uppercase text-white font-bold"
                  style={{
                    fontSize: '29px'
                  }}
                >
                  ahs manajemen
                </label>
              </div>
              <div 
                className="flex space-x-1 text-center text-white px-20"
                style={{
                  fontSize: '23px'
                }}
              >
                Perluas kemampuan, Perbanyak kesempatan
              </div>
              <div
                className="space-y-5 px-20"
              >
                <div
                  className="py-5 px-8 flex items-center space-x-8 rounded-lg"
                  style={{
                    backgroundColor: 'rgb(42 50 112)'
                  }}
                >
                  <BsCheckCircleFill className="h-10 w-10 text-green-500"/>
                  <label 
                    className="text-white"
                    style={{ fontSize: '14px' }}
                  >
                    Materi dapat diakses selamanya
                  </label>
                </div>
                <div
                  className="py-5 px-8 flex items-center space-x-8 rounded-lg"
                  style={{
                    backgroundColor: 'rgb(42 50 112)'
                  }}
                >
                  <BsCheckCircleFill className="h-10 w-10 text-green-500"/>
                  <label 
                    className="text-white"
                    style={{ fontSize: '14px' }}
                  >
                    Belajar dari ahlinya
                  </label>
                </div>
                <div
                  className="py-5 px-8 flex items-center space-x-8 rounded-lg"
                  style={{
                    backgroundColor: 'rgb(42 50 112)'
                  }}
                >
                  <BsCheckCircleFill  className="h-10 w-10 text-green-500"/>
                  <label 
                    className="text-white"
                    style={{ fontSize: '14px' }}
                  >
                    Sertifikat untuk tiap kelasnya
                  </label>
                </div>
              </div>

            </div>

          </div>
          <div
            className="flex-1 bg-white "
          >
            <div
              className="pt-5"
            >
              <div
                className="py-5 px-10 overflow-y-auto"
                style={{
                  height: '700px'
                }}
              >
                {
                  isSuccess === null ?
                    (
                      <>
                      <div
                        className="flex justify-center py-5"
                      >
                        <BsFillShieldLockFill className="h-20 w-20 text-green-800"/>
                      </div>
                      <div
                        className="py-5 text-center font-bold"
                        style={{
                          fontSize: '14px'
                        }}
                      >
                        Reset Ulang Password
                      </div>
                      <div
                        className="flex justify-center"
                      >
                        <div
                          className="w-2/3 space-y-5"
                        >
                          <div
                            className="space-y-1"
                          >
                            <div
                              className=""
                              style={{
                                fontSize: '12px'
                              }}
                            >
                              Password baru
                            </div>
                            <div
                              className="relative"
                            >
                              <input 
                                type={form.passwordShown ? 'text' : 'password'}
                                className="w-full p-3 pr-10 border border-gray-300 rounded-md outline-green-300"
                                placeholder="Masukan password baru"
                                style={{
                                  fontSize: '14px'
                                }}
                                value={form.password}
                                onChange={e => setForm({ ...form, password: e.target.value })}
                              />
                              <div class="flex absolute inset-y-0 right-3 items-center pl-3">
                                <button
                                  onClick={() => setForm({ ...form, passwordShown: !form.passwordShown })}
                                >
                                  <AiFillEye className="w-5 h-5"/>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            className="space-y-1"
                          >
                            <div
                              className=""
                              style={{
                                fontSize: '12px'
                              }}
                            >
                              Konfirmasi password baru
                            </div>
                            <div
                              className="relative"
                            >
                              <input 
                                type={form.passwordConfirmationShown ? 'text' : 'password'}
                                className="w-full p-3 pr-10 border border-gray-300 rounded-md outline-green-300"
                                placeholder="Masukan password baru"
                                style={{
                                  fontSize: '14px'
                                }}
                                value={form.passwordConfirmation}
                                onChange={e => setForm({ ...form, passwordConfirmation: e.target.value })}
                              />
                              <div class="flex absolute inset-y-0 right-3 items-center pl-3">
                                <button
                                  onClick={() => setForm({ ...form, passwordConfirmationShown: !form.passwordConfirmationShown })}
                                >
                                  <AiFillEye className="w-5 h-5"/>
                                </button>
                              </div>
                            </div>
                          </div>
                          
                          <div>
                            <button
                              className="w-full py-2 flex justify-center bg-green-800 hover:bg-green-700 text-white rounded-md"
                              style={{
                                fontSize: '14px'
                              }}
                              onClick={onResetPassword}
                            >
                              {
                                isProcessing ?
                                  <RiLoader5Fill className="h-5 w-5 animate-spin"/>
                                  :
                                  'Ubah password'
                              }
                            </button>
                          </div>
                        </div>

                      </div>
                      </>
                    )
                    :
                    isSuccess ?
                      (<div
                        className="flex items-center w-full h-full"
                      >
                        <div
                          className="px-10 overflow-y-auto w-full flex items-center"
                          style={{
                            height: '400px',
                            marginTop: '-200px'
                          }}
                        >
                          <div
                            className="py-5 text-center space-y-8"
                            style={{
                              fontSize: '14px'
                            }}
                          >
                            <div
                              className="flex justify-center"
                            >
                              <AiFillCheckCircle className="h-20 w-20 text-green-500"/>
                            </div>
                            <div
                              className="space-y-3"
                            >
                              <div 
                                className="font-bold"
                                style={{
                                  fontSize: '20px'
                                }}
                              >
                                Password berhasil diubah!
                              </div>
                              <div
                                className="px-16"
                              >
                                Klik tombol di bawah untuk kembali login.
                              </div>
                              <div
                                className="flex justify-center py-5"
                              >
                                <Link
                                  to={'/login'}
                                >
                                <button
                                  className="py-2 px-10 rounded-sm bg-blue-700 hover:bg-blue-500 text-white"
                                  style={{
                                    fontSize: '14px'
                                  }}
                                >
                                  Login
                                </button>
                                </Link>
                              </div>
    
                            </div>
                          </div>
                        </div>
                      </div>)
                      :
                      (<div
                        className="flex items-center w-full h-full"
                      >
                        <div
                          className="px-10 overflow-y-auto w-full flex items-center"
                          style={{
                            height: '400px',
                            marginTop: '-200px'
                          }}
                        >
                          <div
                            className="py-5 text-center space-y-8"
                            style={{
                              fontSize: '14px'
                            }}
                          >
                            <div
                              className="flex justify-center"
                            >
                              <AiFillCloseCircle className="h-20 w-20 text-red-500"/>
                            </div>
                            <div
                              className="space-y-3"
                            >
                              <div 
                                className="font-bold"
                                style={{
                                  fontSize: '20px'
                                }}
                              >
                                Terjadi kesalahan!
                              </div>
                              <div
                                className="px-16"
                              >
                                Kontak kami untuk informasi lebih lanjut
                              </div>
    
                            </div>
                          </div>
                        </div>
                      </div>)
                }
                
              </div>
              
            </div>
          </div>
        </div>


      </div>

    </div>
  )

}

export default ResetPasswordPage;