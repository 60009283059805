import axios from "axios"

export const login = async (payload) => {
  const finalUrl =
      window._HOST + "affiliater/login"

  const response =
      await axios.post(finalUrl, payload)

  return response.data
}