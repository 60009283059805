import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router"
import { AiFillCheckCircle, AiFillEye, AiOutlineClockCircle, AiOutlineCloseCircle } from "react-icons/ai"
import { RiLoader5Fill } from "react-icons/ri"
import { getProgram, register } from "./apis/Api";
import { handleResponse200 } from "../../utils/HttpUtils";
import { notifyError } from "../../utils/Notification";
import Select from "react-select"
import { MdRadioButtonUnchecked, MdOutlineCheckCircle } from "react-icons/md"
import { BiMailSend } from "react-icons/bi"
import { formatRupiah } from "../../utils/NumericHelper";
import { useSelector } from "react-redux";
import { isPhone } from "../../utils/PlatformHelper";

const RegistrationPage = () => {

  const navigate = useNavigate()

  const { 
    systemProfile,
    screenSize
  } = useSelector(state => state.global)

  useEffect(() => {
    document.body.style.backgroundColor =  isPhone(screenSize.width) ? 'white' : '#232745';
  }, [screenSize.width])

  const [hashImage, setHashImage] = useState(Date.now())

  const [form, setForm] = useState({
    fullName: '',
    email: '',
    emailConfirmation: '',
    noHp: '',
    password: '',
    passwordConfirmation: '',
    passwordShown: false,
    passwordConfirmationShown: false
  });

  const [isProcessing, setIsProcessing] = useState(false)

  const [isSuccessRegistration, setIsSuccessRegistration] = useState(false)

  const [errors, setErrors] = useState([])

  function onRegister() {
    const errors = []

    if (form.fullName === '') {
      errors.push('Nama lengkap tidak boleh kosong')
    }

    if (form.email === '') {
      errors.push('Email tidak boleh kosong')
    }

    if (form.emailConfirmation === '') {
      errors.push('Email konfirmasi tidak boleh kosong')
    }

    if (form.email !== form.emailConfirmation) {
      errors.push('Email dan Email konfirmasi tidak sama')
    }

    if (form.noHp === '') {
      errors.push('No Whatsapp tidak boleh kosong')
    } else {
      if (!form.noHp.startsWith('8')) {
        errors.push('No Whatsapp harus diawali angka 8 ')
      }
    }

    if (form.password === '') {
      errors.push('Password tidak boleh kosong')
    }
    if (form.passwordConfirmation === '') {
      errors.push('Password konfirmasi tidak boleh kosong')
    }

    if (form.password !== form.passwordConfirmation) {
      errors.push('Password dan password konfirmasi tidak sama')
    }

    if (errors.length > 0) {
      console.log('ada error')
      console.log(errors)
      return;
    }

    setIsProcessing(true)

    const payload = {
      fullName: form.fullName,
      email: form.email,
      noHp: form.noHp,
      password: form.password,
    }

    register(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setIsSuccessRegistration(true)
          },
          onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
          }),
          onUnAuth: error => {
              
          },
          onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
          })
        })
      })
      .catch(error => {
        notifyError('Critical Error. Please Contact Our Developer')
      })
      .finally(() => setIsProcessing(false))
    
  }

  if (!systemProfile) return null;

  return (
    <div
      className="w-screen h-screen"
    >
      <div
        className="py-10 flex justify-center"
      >

        <div
          className="relative rounded-sm flex"
          style={{
            width: isPhone(screenSize.width) ? '100%' : '800px',
            height: isPhone(screenSize.width) ? '100%' : '800px'
          }}
        >
          {isProcessing && (
            <div 
              className="absolute top-0 left-0 right-0 bottom-0 z-10"
            />
          )}
          <div
            className="flex-1 bg-white "
          >

            {
              isSuccessRegistration ?
                (
                  <div
                    className="flex items-center w-full h-full"
                  >
                    <div
                      className="px-10 overflow-y-auto w-full flex items-center"
                      style={{
                        height: '650px',
                        // marginTop: '-200px'
                      }}
                    >
                      <div
                        className="py-5 text-center space-y-8"
                        style={{
                          fontSize: '14px'
                        }}
                      >
                        <div
                          className="flex justify-center items-center space-x-3"
                        >
                          <AiFillCheckCircle className="h-20 w-20 text-green-500"/>
                          <BiMailSend className="h-20 w-20 text-green-500"/>
                        </div>
                        <div
                          className="space-y-3"
                        >
                          <div 
                            className="font-bold"
                            style={{
                              fontSize: '20px'
                            }}
                          >
                            Registrasi Akun Affiliater Berhasil!
                          </div>
                          <div
                            className="px-16"
                          >
                            Kami telah mengirimkan email verifikasi. Cek inbox Email dan segera lakukan verifikasi.
                          </div>
                          <div className="space-y-1.5">
                            <button 
                              className="p-2 px-8 rounded-md border border-blue-500 hover:bg-blue-500 hover:text-white"
                              onClick={() => navigate("/login")}
                            >
                              Kembali
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                )
                :
                (
                  <>
                    <div
                      className={
                        "pt-2 border-b flex justify-between " +
                        (isPhone(screenSize.width) ? ' px-5 ' : ' px-24 ')
                      }
                    >
                      <Link
                        to={'/login'}
                      >
                        <button
                          className="p-3 px-8 border-b-4 border-transparent font-bold"
                          style={{
                            fontSize: '14px'
                          }}
                        >
                          Masuk
                        </button>
                      </Link>
                      <Link
                        to={'/registration'}
                      >
                        <button
                          className="p-3 px-8 border-b-4 border-green-500 text-green-700 font-bold"
                          style={{
                            fontSize: '14px'
                          }}
                        >
                          Daftar
                        </button>
                      </Link>
                    </div>
                    <div
                      className="pt-5"
                    >
                      <div
                        className={
                          "py-5 overflow-y-auto " +
                          (isPhone(screenSize.width) ? '' : ' px-10 ')
                        }
                        style={{
                          height: isPhone(screenSize.width) ? '100%' : '650px'
                        }}
                      >
                        <div
                          className="flex justify-center"
                        >
                          <div
                            className={
                              " space-y-5 " +
                              (isPhone(screenSize.width) ? 'px-10' : ' w-2/3 ')
                            }
                          >
                            <div
                            >
                              <div
                                className="text-center uppercase font-bold"
                              >
                                Formulir pendaftaran Affiliater
                              </div>
                              <div
                                className="flex justify-center py-5"
                              >
                                <img src={systemProfile.companyLogo + '?' + hashImage} width={'250px'}/>
                              </div>
                            </div>
                            {errors.length > 0 && (
                              <div
                                className="py-5"
                              >
                                <div
                                  className="relative p-3 rounded-md bg-red-500 text-white"
                                >
                                  <div className="absolute right-2 top-1">
                                    <button
                                      onClick={() => setErrors([])}
                                    >
                                      <AiOutlineCloseCircle className="text-white h-5 w-5"/>
                                    </button>
                                  </div>
                                  <ol
                                    className="text-left"
                                    style={{
                                      fontSize: '14px'
                                    }}
                                  >
                                    {errors.map(err => (
                                      <li className="py-1 text-left">{err}</li>
                                    ))}
                                  </ol>
                                </div>
                              </div>
                            )}
                            <div
                              className="space-y-1"
                            >
                              <div
                                className=""
                                style={{
                                  fontSize: '14px'
                                }}
                              >
                                <div>
                                  Nama Lengkap*
                                </div>
                                <div
                                  className="italic text-gray-500"
                                  style={{ fontSize: '12px' }}
                                >
                                  Nama lengkap anda
                                </div>
                              </div>
                              <div
                                className="relative"
                              >
                                <input 
                                  className="w-full p-3 border border-gray-300 rounded-md outline-green-300"
                                  placeholder="Nama Lengkap"
                                  style={{
                                    fontSize: '14px'
                                  }}
                                  value={form.fullName}
                                  onChange={e => setForm({ ...form, fullName: e.target.value })}
                                />
                              </div>
                            </div>
                            <div
                              className="space-y-1"
                            >
                              <div
                                className=""
                                style={{
                                  fontSize: '14px'
                                }}
                              >
                                <div>
                                  No. HP (Whatsapp)*
                                </div>
                                <div
                                  className="italic text-gray-500"
                                  style={{ fontSize: '12px' }}
                                >
                                  No Hp yang dapat dihubungi
                                </div>
                              </div>
                              <div
                                className="relative"
                              >
                                <input 
                                  className="w-full p-3 pl-12 border border-gray-300 rounded-md outline-green-300"
                                  placeholder="(contoh: 8987654321)"
                                  style={{
                                    fontSize: '14px'
                                  }}
                                  value={form.noHp}
                                  onChange={e => setForm({ ...form, noHp: e.target.value })}
                                />
                                <div 
                                  class="flex absolute w-10 inset-y-0 left-0 items-center justify-center border-r border-gray-300"
                                  style={{
                                    fontSize: '14px'
                                  }}
                                >
                                  +62
                                </div>
                              </div>
                            </div>
                            
                            <div
                              className={
                                "grid gap-2 "+
                                (isPhone(screenSize.width) ? '' : ' grid-cols-2 ')
                              }
                            >
                              <div
                                className="space-y-1"
                              >
                                <div
                                  className=""
                                  style={{
                                    fontSize: '14px'
                                  }}
                                >
                                  <div>
                                    Email*
                                  </div>
                                  <div
                                    className="italic text-gray-500"
                                    style={{ fontSize: '12px' }}
                                  >
                                    Email akun pendaftar
                                  </div>
                                </div>
                                <div>
                                  <input 
                                    className="w-full p-3 border border-gray-300 rounded-md outline-green-300"
                                    placeholder="Email"
                                    style={{
                                      fontSize: '14px'
                                    }}
                                    value={form.email}
                                    onChange={e => setForm({ ...form, email: e.target.value })}
                                  />
                                </div>
                              </div>
                              <div
                                className="space-y-1"
                              >
                                <div
                                  className=""
                                  style={{
                                    fontSize: '14px'
                                  }}
                                >
                                  <div>
                                    Konfirmasi Email*
                                  </div>
                                  <div
                                    className="italic text-gray-500"
                                    style={{ fontSize: '12px' }}
                                  >
                                    Konfirmasi ulang email
                                  </div>
                                </div>
                                <div>
                                  <input 
                                    className="w-full p-3 border border-gray-300 rounded-md outline-green-300"
                                    placeholder="Konfirmasi Email"
                                    style={{
                                      fontSize: '14px'
                                    }}
                                    value={form.emailConfirmation}
                                    onChange={e => setForm({ ...form, emailConfirmation: e.target.value })}
                                  />
                                </div>
                              </div>

                            </div>
                            <div
                              className={
                                "grid gap-2 "+
                                (isPhone(screenSize.width) ? '' : ' grid-cols-2 gap-x-2')
                              }
                            >
                              <div
                                className="space-y-1"
                              >
                                <div
                                  className=""
                                  style={{
                                    fontSize: '14px'
                                  }}
                                >
                                  <div>
                                    Password*
                                  </div>
                                  <div
                                    className="italic text-gray-500"
                                    style={{ fontSize: '12px' }}
                                  >
                                    Password akun pendaftar
                                  </div>
                                </div>
                                <div
                                  className="relative"
                                >
                                  <input 
                                    type={form.passwordShown ? 'text' : 'password'}
                                    className="w-full p-3 pr-10 border border-gray-300 rounded-md outline-green-300"
                                    placeholder="Password"
                                    style={{
                                      fontSize: '14px'
                                    }}
                                    value={form.password}
                                    onChange={e => setForm({ ...form, password: e.target.value })}
                                  />
                                  <div class="flex absolute inset-y-0 right-3 items-center pl-3">
                                    <button
                                      onClick={() => setForm({ ...form, passwordShown: !form.passwordShown })}
                                    >
                                      <AiFillEye className="w-5 h-5"/>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="space-y-1"
                              >
                                <div
                                  className=""
                                  style={{
                                    fontSize: '14px'
                                  }}
                                >
                                  <div>
                                    Konfirmasi Password*
                                  </div>
                                  <div
                                    className="italic text-gray-500"
                                    style={{ fontSize: '12px' }}
                                  >
                                    Konfirmasi ulang password
                                  </div>
                                </div>
                                <div
                                  className="relative"
                                >
                                  <input 
                                    type={form.passwordConfirmationShown ? 'text' : 'password'}
                                    className="w-full p-3 pr-10 border border-gray-300 rounded-md outline-green-300"
                                    placeholder="Password"
                                    style={{
                                      fontSize: '14px'
                                    }}
                                    value={form.passwordConfirmation}
                                    onChange={e => setForm({ ...form, passwordConfirmation: e.target.value })}
                                  />
                                  <div class="flex absolute inset-y-0 right-3 items-center pl-3">
                                    <button
                                      onClick={() => setForm({ ...form, passwordConfirmationShown: !form.passwordConfirmationShown })}
                                    >
                                      <AiFillEye className="w-5 h-5"/>
                                    </button>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div>
                              <button
                                className="w-full py-2 flex justify-center bg-green-800 hover:bg-green-700 text-white rounded-md"
                                style={{
                                  fontSize: '14px'
                                }}
                                onClick={onRegister}
                              >
                                {
                                  isProcessing ?
                                    <RiLoader5Fill className="h-5 w-5 animate-spin"/>
                                    :
                                    'Daftar'
                                }
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                      
                      <div
                        className="py-5 text-center border-t"
                        style={{
                          fontSize: '14px'
                        }}
                      >
                        <label>Sudah memiliki akun? Silahkan </label>
                        <Link to="/login"><span className="text-green-500 font-bold">masuk</span></Link>
                      </div>
                    </div>
                  </>
                )
            }


          </div>

        </div>


      </div>

    </div>
  )

}

export default RegistrationPage;