import axios from "axios"

export const resetPassword = async (payload) => {
  const finalUrl =
      window._HOST + "affiliater/reset-password"

  const response =
      await axios.put(finalUrl, payload)

  return response.data
}