import axios from "axios"

export const getAffLink = async (payload) => {
  const finalUrl =
    window._HOST + "affiliater/affiliater-link"

  const response =
    await axios.get(finalUrl)

  return response.data
}