import axios from "axios"

export const getBank = async () => {
  const finalUrl =
    window._HOST + "affiliater/bank"

  const response =
    await axios.get(finalUrl)

  return response.data
}

export const updateProfile = async (payload) => {
  const finalUrl =
    window._HOST + "affiliater/profile"

  const response =
    await axios.put(finalUrl, payload)

  return response.data
}

export const updateRekening = async (payload) => {
  const finalUrl =
      window._HOST + "affiliater/rekening"

  const response =
      await axios.put(finalUrl, payload)

  return response.data
}

export const getProvinsi = async () => {
  const finalUrl =
      window._HOST + "public/provinsi"

  const response =
      await axios.get(finalUrl)

  return response.data
}

export const getKabupaten = async (idProvinsi) => {
  const finalUrl =
      window._HOST + "public/kabupaten/?id-provinsi="+idProvinsi

  const response =
      await axios.get(finalUrl)

  return response.data
}

export const getKecamatan = async (idKabupaten) => {
  const finalUrl =
      window._HOST + "public/kecamatan/?id-kabupaten="+idKabupaten

  const response =
      await axios.get(finalUrl)

  return response.data
}