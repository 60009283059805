
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router"
import { setIsChangePasswordDialogOpen, setIsHomeProcess, setIsLogoutDialogOpen, setMember, setScreenSize, setSystemProfile } from "./app/GlobalSlice";
import ForgetPasswordPage from "./pages/ForgetPassword/ForgetPassword";
import LoginPage from "./pages/Login/LoginPage";
import MailVerificationPage from "./pages/MailVerification/MailVerificationPage";
import RegistrationPage from "./pages/Registration/RegistrationPage";
import ResetPasswordPage from "./pages/ResetPassword/ResetPasswordPage";

import axios from "axios";
import LoadScreenWrapper from "./components/LoadScreenWrapper";
import { changePassword, getSessionProfile, getSystemProfile, logout } from "./app/Api";
import { notifyError, notifySuccess } from "./utils/Notification";
import { handleResponse200 } from "./utils/HttpUtils";
import HomePage from "./pages/Home/HomePage";
import ChangePasswordDialog from "./components/ChangePasswordDialog";
import LogoutDialog from "./components/LogoutDialog";
import VisitorPage from "./pages/Visitor/VisitorPage";
import ParticipantPage from "./pages/Participant/ParticipantPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import IncomePage from "./pages/Income/IncomePage";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

// const getProfile = async () => {
  
//   const res = await axios.get(`${window._HOST}member/profile`);

//   return res.data
// }

function App() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { 
    isHomeProcess,
    isChangePassDialogOpen,
    isProfileDialogOpen,
    isLogoutDialogOpen
  } = useSelector(state => state.global)

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  useEffect(() => {
    dispatch(setIsHomeProcess(true))

    getSystemProfile()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            dispatch(setSystemProfile(payload))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {
        notifyError('Unexpected Error')
      })
      .finally(() => dispatch(setIsHomeProcess(false)))

  }, [])

  useEffect(() => {
    dispatch(setScreenSize(windowDimensions))
  }, [windowDimensions])

  useEffect(() => {
    console.log('get session')
    dispatch(setIsHomeProcess(true))

    getSessionProfile()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            dispatch(setMember(payload))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(err => {
        notifyError("UnExpected Error. Please Contact Developer")
      })
      .finally(() => {
        dispatch(setIsHomeProcess(false))
      })

  }, [])

  function onLogout() {
    dispatch(setIsHomeProcess(true))

    logout()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: _ => {
            dispatch(setIsLogoutDialogOpen(false))
            navigate("/login")
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            navigate("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {
        notifyError("Critical Error. Please Contact Developer")
      })
      .finally(() => {
        dispatch(setIsHomeProcess(false))
      })

  }

  function onChangePassword(oldPassword, newPassword) {
    const payload = {
      oldPassword: oldPassword,
      newPassword: newPassword
    }

    dispatch(setIsHomeProcess(true))

    changePassword(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: _ => {
            dispatch(setIsChangePasswordDialogOpen(false))
            notifySuccess("Berhasil ubah password")
          },
          onRecovFailure: errors => {
            errors.forEach(err => {
              notifyError(err)
            });
          },
          onUnAuth: () => {
            console.log("Token not found")
          },
          onTechnicalError: () => { }
        })
      })
      .catch(error => {})
      .finally(() => dispatch(setIsHomeProcess(false)))
  }

  return (
    <>
    {isProcessing && (
      <div
        className="fixed w-screen h-screen bg-black opacity-50 z-10"
      >
        
      </div>
    )}
    <LoadScreenWrapper
      isShow={isHomeProcess}
    />
    <Toaster
      position="top-center"
    />
    <ChangePasswordDialog
      isShow={isChangePassDialogOpen}
      onClose={() => dispatch(setIsChangePasswordDialogOpen(false))}
      onChangePassword={onChangePassword}
    />
    <LogoutDialog
      isShow={isLogoutDialogOpen}
      onClose={() => dispatch(setIsLogoutDialogOpen(false))}
      onLogout={onLogout}
    />
    <Routes>
      <Route path="/*">
        <Route index element={<LoginPage />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/forget-password" element={<ForgetPasswordPage />} />
      <Route path="/mail-verification/:id/:token" element={<MailVerificationPage />} />
      <Route path="/reset-password/:id/:token" element={<ResetPasswordPage />} />

      <Route path="/home" element={<HomePage />} />
      <Route path="/home/visitor" element={<VisitorPage />} />
      <Route path="/home/participant" element={<ParticipantPage />} />
      <Route path="/home/income" element={<IncomePage />} />
      <Route path="/home/profile" element={<ProfilePage />} />
    </Routes>
    </>
  );
}

export default App;
