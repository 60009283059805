import { formatRupiah } from "../../../utils/NumericHelper";

const KomisiComp = ({ progIncomes }) => {
  return (
    <div className="p-3">
      <div>
        <table className="w-full table-fixed">
          <thead className="border-b bg-gray-200 font-medium dark:border-neutral-500">
            <tr>
              <th className="w-2/4 font-light py-1">
                Program Masuk
              </th>
              <th className="w-1/4 font-light py-1">
                Pendaftaran
              </th>
              <th className="w-1/4 font-light py-1">
                Uang Pangkal
              </th>
            </tr>
          </thead>
          <tbody>
            { 
              progIncomes.length == 0 ?
                <tr colspan="3">Tidak ada penerimaan</tr>
              :
              progIncomes.map(progIncome => (
                <tr key={progIncome.programId} className="border-b">
                  <td className="py-2">
                    {progIncome.programName}
                  </td>
                  <td className="text-right">
                    {progIncome.regFee ? formatRupiah(progIncome.regFee) : progIncome.regFee}
                  </td>
                  <td className="text-right">
                    {progIncome.upFee ? formatRupiah(progIncome.upFee) : progIncome.upFee}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default KomisiComp;