import { useDispatch, useSelector } from "react-redux"
import { isPhone } from "../../utils/PlatformHelper"
import Navbar from "../../components/Navbar";
import { useEffect, useState } from "react";
import { setActivePage } from "../../app/GlobalSlice";
import KomisiComp from "./Component/Komisi";
import PencairanComp from "./Component/Pencairan";
import { getAffIncome } from "./Api";

const IncomePage = () => {
    const dispatch = useDispatch();

    const { 
      member,
      screenSize
    } = useSelector(state => state.global)

    const [pageOpts, setPageOpts] = useState({
      datas: [
        { label: 'Potensi Komisi', value: 'komisi' },
        { label: 'Pencairan', value: 'pencairan' }
      ],
      selectedOpt: { label: 'Potensi Komisi', value: 'komisi' }
    })

    const [progIncomes, setProgIncomes] = useState([])
    const [loadProgIncomes, setLoadProgIncomes] = useState(true)

    useEffect(() => {
      document.body.style.backgroundColor =  isPhone(screenSize.width) ? 'white' : '#232745';

      dispatch(setActivePage('income'))

      getAffIncome()
        .then(res => {
          if (res.success) {
            let incomes = res.payload.map(pIn => ({
              programName: pIn.affProgramName,
              programId: pIn.affProgramId,
              regFee: pIn.affRegFee,
              upFee: pIn.affUPFee
            }))
            setProgIncomes(incomes)
          }
        })
        .finally(() => {
          setLoadProgIncomes(false)
        })
    }, [])

    return (
      <div className="flex justify-center" >
        <div
            className={
              isPhone(screenSize.width) ? " w-full space-y-3  " : "w-[800px] pt-10 space-y-3"
            }
        >
          <Navbar />
          <div
            className="bg-white rounded-lg divide-y divide-gray-200 z-0"
          >
            <div
              className="p-3"
            >
              <div
                className="uppercase font-bold"
              >
                Data Komisi
              </div>
              <div
                className="italic"
                style={{
                  fontSize: '14px'
                }}
              >
                Potensi Komisi dan Pencairan
              </div>
            </div>
            <div 
              className="pb-10"
            >
              <div
                className="pt-2 px-2 bg-gray-500"
              >
                {pageOpts.datas.map(p => (
                  <button 
                    className={
                      "w-36 py-2 rounded-tl-md rounded-tr-md " +
                      (p.value === pageOpts.selectedOpt.value ? ' bg-white ' : ' bg-gray-300 ' )
                    }
                    onClick={() => setPageOpts({ ...pageOpts, selectedOpt: p })}
                  >
                    {p.label}
                  </button>
                ))}
              </div>
              <div>
                {
                  pageOpts.selectedOpt.value == 'komisi' ?
                    loadProgIncomes ?
                      (<div>Load data...</div>)
                      :
                      (<KomisiComp progIncomes={progIncomes} />)
                    :
                    (<PencairanComp />)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default IncomePage;