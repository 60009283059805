import axios from "axios"

export const getPendaftarSummary = async (payload) => {
  const finalUrl =
    window._HOST + "affiliater/pendaftar-summary"

  const response =
    await axios.post(finalUrl, payload)

  return response.data
}

export const getPendaftarDaily = async (payload) => {
  const finalUrl =
    window._HOST + "affiliater/pendaftar-daily"

  const response =
    await axios.post(finalUrl, payload)

  return response.data
}