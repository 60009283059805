import { Transition, Dialog } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isPhone } from '../../utils/PlatformHelper'

const UploadPhotoDialog = ({
  onClose,
  onFileSelected
}) => {

  const { screenSize } = useSelector(state => state.global)

  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState(null)

  const fileReader = new FileReader()
  fileReader.onload = r => {
    setPreview(r.target.result)
  }

  useEffect(() => {
    if (!file) {
      setPreview(null)
      return
    }
    fileReader.readAsDataURL(file)

    return () => {
      setPreview(null)
      setFile(null)
    }
  }, [file])

  function onSelectFile(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(null)
      return
    }

    setFile(e.target.files[0])
  }

  return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div 
          className={
            "min-h-screen text-center " +
            (isPhone(screenSize.width) ? "" : " px-4 flex justify-center ")
          }
        >
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={"fixed inset-0 bg-opacity-70 " + (screenSize.width < 798 ? "bg-white" : "bg-gray-200")} />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={"absolute top-0 " + (screenSize.width > 798 ? "left-1/2" : "left-0 top-0")}>
              <div
                className={
                  "inline-block overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl " +
                  (screenSize.width > 798 ? "p-5 my-8 rounded-2xl" : " px-3 ")
                }
                style={
                  screenSize.width > 798 ?
                    {
                      position: "absolute",
                      top: "10%",
                      left: "-300px",
                      width: "600px"
                    }
                    :
                    {
                      position: "absolute",
                      minWidth: screenSize.width + "px",
                      minHeight: screenSize.height + "px",
                    }
                }
              >
                <Dialog.Title
                  as="h3"
                  className="py-3 text-lg font-medium leading-6 text-gray-900 border-b border-gray-200"
                >
                  Unggah Photo Calon Murid
                </Dialog.Title>
                <div className="w-full py-2 flex items-center justify-center">
                  <div className="w-full p-2 flex bg-gray-100 text-gray-600 rounded-md text-sm">
                    <ul>
                      <li className='py-1'><span className="inline-block w-36">Maks ukuran file</span>: 2 MB</li>
                      <li className='py-1'><span className="inline-block w-36">Jenis file</span>: JPG, JPEG, PNG, atau PDF</li>
                    </ul>
                  </div>
                </div>
                <div className="p-5 flex items-center justify-center ">
                  <img
                    src={preview}
                    className="rounded-md"
                    style={{
                      maxHeight: "300px"
                    }}
                  />
                </div>
                <div className="p-2 flex items-center justify-center ">
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg, application/pdf"
                    className="p-3 rounded-lg font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none"
                    onChange={onSelectFile}
                  />
                </div>

                <div className={"w-full flex py-2 mt-4 space-x-2 " + (screenSize.width < 798 ? "justify-center" : "")}>
                  <div>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                      onClick={onClose}
                    >
                      Batal
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={() => onFileSelected(file)}
                    >
                      Simpan
                    </button>
                  </div>
                </div>

              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )

}

export default UploadPhotoDialog;