import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai"
import { RiLoader5Fill } from "react-icons/ri"
import { login } from "./apis/Api";
import { handleResponse200 } from "../../utils/HttpUtils";
import { notifyError } from "../../utils/Notification";
import { BsCheckCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setMember } from "../../app/GlobalSlice";
import { isPhone } from "../../utils/PlatformHelper";

const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { 
    systemProfile,
    screenSize
  } = useSelector(state => state.global)

  const [form, setForm] = useState({
    email: '',
    password: '',
    passwordShown: false
  });

  const [isProcessing, setIsProcessing] = useState(false)

  const [hashImage, setHashImage] = useState(Date.now())

  useEffect(() => {
    document.body.style.backgroundColor =  isPhone(screenSize.width) ? 'white' : '#232745';
  }, [screenSize.width])

  function onLogin() {
    const errors = [];

    if (form.email === '') {
      errors.push('Email tidak boleh kosong');
    }

    if (form.password === '') {
      errors.push('Password tidak boleh kosong')
    }

    if (errors.length > 0) {
      return;
    }

    setIsProcessing(true)

    const payload = {
      username: form.email,
      password: form.password
    }

    login(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setIsProcessing(false)
            dispatch(setMember(payload))
            navigate('/home')
          },
          onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
          }),
          onUnAuth: error => {
              
          },
          onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
          })
        })
      })
      .catch(error => {

      })
      .finally(() => setIsProcessing(false))

  }

  if (!systemProfile) return null;

  return (
    <div
      className="w-screen h-screen"
    >
      <div
        className="py-10 flex justify-center"
      >

        <div
          className="relative rounded-sm flex"
          style={{
            width: isPhone(screenSize.width) ? '100%' : '800px',
            height: isPhone(screenSize.width) ? '100%' : '800px'
          }}
        >
          {isProcessing && (
            <div 
              className="absolute top-0 left-0 right-0 bottom-0 z-10"
            />
          )}
          <div
            className="flex-1 bg-white "
          >
            <div
              className={
                "pt-2 border-b flex justify-between " +
                (isPhone(screenSize.width) ? ' px-5 ' : ' px-24 ')
              }
            >
              <Link
                to={'/login'}
              >
                <button
                  className="p-3 px-8 border-b-4 border-green-500 text-green-700 font-bold"
                  style={{
                    fontSize: '14px'
                  }}
                >
                  Masuk
                </button>
              </Link>
              <Link
                to={'/registration'}
              >
                <button
                  className="p-3 px-8 border-b-4 border-transparent font-bold"
                  style={{
                    fontSize: '14px'
                  }}
                >
                  Daftar
                </button>
              </Link>
            </div>
            <div
              className="pt-5"
            >
              <div
                className={
                  "py-5 overflow-y-auto " +
                  (isPhone(screenSize.width) ? '' : ' px-10 ')
                }
                style={{
                  height: isPhone(screenSize.width) ? '100%' : '650px'
                }}
              >
                <div
                  className="text-center font-bold uppercase"
                >
                  PMB Affiliater 
                </div>
                <div
                  className="flex justify-center py-5"
                >
                  <img src={systemProfile.companyLogo + '?' +hashImage} width={'250px'}/>
                </div>
                <div
                  className="flex justify-center"
                >
                  <div
                    className={
                      " space-y-5 " +
                      (isPhone(screenSize.width) ? '' : ' w-2/3 ')
                    }
                  >
                    <div
                      className="space-y-1"
                    >
                      <div
                        className=""
                        style={{
                          fontSize: '14px'
                        }}
                      >
                        <div>
                          Email
                        </div>
                        <div
                          className="italic text-gray-500"
                          style={{ fontSize: '12px' }}
                        >
                          Masukan email akun affiliater anda
                        </div>
                      </div>
                      <div>
                        <input 
                          className="w-full p-3 border border-gray-300 rounded-md outline-green-300"
                          placeholder="Email"
                          style={{
                            fontSize: '14px'
                          }}
                          value={form.email}
                          onChange={e => setForm({ ...form, email: e.target.value })}
                        />
                      </div>
                    </div>
                    <div
                      className="space-y-1"
                    >
                      <div
                        className=""
                        style={{
                          fontSize: '14px'
                        }}
                      >
                        <div>
                          Password
                        </div>
                        <div
                          className="italic text-gray-500"
                          style={{ fontSize: '12px' }}
                        >
                          Masukan password akun affiliater anda
                        </div>
                      </div>
                      <div
                        className="relative"
                      >
                        <input 
                          type={form.passwordShown ? 'text' : 'password'}
                          className="w-full p-3 pr-10 border border-gray-300 rounded-md outline-green-300"
                          placeholder="Password"
                          style={{
                            fontSize: '14px'
                          }}
                          value={form.password}
                          onChange={e => setForm({ ...form, password: e.target.value })}
                        />
                        <div class="flex absolute inset-y-0 right-3 items-center pl-3">
                          <button
                            onClick={() => setForm({ ...form, passwordShown: !form.passwordShown })}
                          >
                            <AiFillEye className="w-5 h-5"/>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="text-right"
                      style={{
                        fontSize: '13px'
                      }}
                    >
                      <Link to='/forget-password'>
                        <label className="text-blue-600 cursor-pointer">Lupa Password</label>
                      </Link>
                    </div>
                    <div>
                      <button
                        className="w-full py-2 flex justify-center bg-green-800 hover:bg-green-700 text-white rounded-md"
                        style={{
                          fontSize: '14px'
                        }}
                        onClick={onLogin}
                      >
                        {
                          isProcessing ?
                            <RiLoader5Fill className="h-5 w-5 animate-spin"/>
                            :
                            'Masuk'
                        }
                      </button>
                    </div>
                  </div>

                </div>
              </div>
              
              <div
                className="py-5 text-center border-t"
                style={{
                  fontSize: '14px'
                }}
              >
                <label>Belum memiliki akun? Silahkan </label>
                <Link to="/registration"><span className="text-green-500 font-bold">daftar</span></Link>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  )

}

export default LoginPage;