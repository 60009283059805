import axios from "axios"

export const forgetPassword = async (email) => {
  const finalUrl =
      window._HOST + "member/forget-password/" + email

  const response =
      await axios.put(finalUrl, {})

  return response.data
}