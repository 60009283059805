import axios from "axios"

export const verifyAccount = async (participantId, token) => {
  const finalUrl =
      window._HOST + "affiliater/mail-verification/" + participantId + "/" + token

  const response =
      await axios.put(finalUrl, {})

  return response.data
}