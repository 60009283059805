import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../app/GlobalSlice";
import Navbar from "../../components/Navbar";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { registerLocale } from "react-datepicker";
import id from 'date-fns/locale/id'
import { GoCalendar } from "react-icons/go";
import { getKunjunganDaily, getKunjunganSummary, getPendaftarDaily, getPendaftarSummary } from "./Api";
import { format } from "date-fns";
import { handleResponse200 } from "../../utils/HttpUtils";
import { notifyError } from "../../utils/Notification";

import { RiLoader5Fill, RiSearch2Line } from "react-icons/ri";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { isPhone } from "../../utils/PlatformHelper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Grafik Pendaftaran',
    },
  },
};

function getDates(startDate, stopDate) {
  const dateArray = new Array();
  var currentDate = new Date(startDate.getTime());

  while (currentDate <= stopDate) {
    const temp = new Date(currentDate.getTime())
    dateArray.push(temp)
    
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
}

function buildData(
  isoDatesRange, 
  labelDates,
  noMediaDates,
  facebookDates,
  instagramDates,
  instagramStoryDates,
  whatsappDates,
  emailDates,
  tiktokDates
) {

  return {
    labels: labelDates,
    datasets: [
      {
        label: 'No Media',
        data: isoDatesRange.map(d => noMediaDates.find(dy => dy.rdtDate === d) ? noMediaDates.find(dy => dy.rdtDate === d).rdtTotal : 0 ),
        borderColor: 'rgb(140, 148, 22)',
        backgroundColor: 'rgba(189, 199, 46)',
      },
      {
        label: 'Facebook',
        data: isoDatesRange.map(d => facebookDates.find(dy => dy.rdtDate === d) ? facebookDates.find(dy => dy.rdtDate === d).rdtTotal : 0 ),
        borderColor: 'rgb(10, 108, 194)',
        backgroundColor: 'rgba(33, 127, 209)',
      },
      {
        label: 'Instagram',
        data: isoDatesRange.map(d => instagramDates.find(dy => dy.rdtDate === d) ? instagramDates.find(dy => dy.rdtDate === d).rdtTotal : 0 ),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Instagram Story',
        data: isoDatesRange.map(d => instagramStoryDates.find(dy => dy.rdtDate === d) ? instagramStoryDates.find(dy => dy.rdtDate === d).rdtTotal : 0 ),
        borderColor: 'rgb(153, 29, 128)',
        backgroundColor: 'rgba(184, 53, 157)',
      },
      {
        label: 'Whatsapp',
        data: isoDatesRange.map(d => whatsappDates.find(dy => dy.rdtDate === d) ? whatsappDates.find(dy => dy.rdtDate === d).rdtTotal : 0 ),
        borderColor: 'rgb(14, 176, 87)',
        backgroundColor: 'rgba(45, 196, 113)',
      },
      {
        label: 'Email',
        data: isoDatesRange.map(d => emailDates.find(dy => dy.rdtDate === d) ? emailDates.find(dy => dy.rdtDate === d).rdtTotal : 0 ),
        borderColor: 'rgb(153, 50, 32)',
        backgroundColor: 'rgba(179, 71, 52)',
      },
      {
        label: 'Tiktok',
        data: isoDatesRange.map(d => tiktokDates.find(dy => dy.rdtDate === d) ? tiktokDates.find(dy => dy.rdtDate === d).rdtTotal : 0 ),
        borderColor: 'rgb(8, 8, 8)',
        backgroundColor: 'rgba(8, 8, 8)',
      },
    ]
  }
}

const ParticipantPage = () => {
  const date = new Date()

  const dispatch = useDispatch()

  const { 
    member,
    screenSize
  } = useSelector(state => state.global)

  const [isLoadSummary, setIsLoadSummary] = useState(false)
  const [isLoadData, setIsLoadData] = useState(false)

  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1))
  const [untilDate, setUntilDate] = useState(date)

  const [pendaftarSummary, setPendaftarSummary] = useState(null)
  const [pendaftarDaily, setPendaftarDaily] = useState([])

  const [chartLabels, setChartLabels] = useState([])

  const [pageOpts, setPageOpts] = useState({
    datas: [
      { label: 'Landing Page', value: 'landing_page' },
      { label: 'Checkout Page', value: 'checkout_page' }
    ],
    selectedOpt: { label: 'Landing Page', value: 'landing_page' }
  })

  useEffect(() => {

    setChartLabels(
      getDates(startDate, untilDate)
    )

    const payload = {
      rbfromDate: format(startDate, 'yyyy-MM-dd'),
      rbuntilDate: format(untilDate, 'yyyy-MM-dd'),
      rbPage: pageOpts.selectedOpt.value  
    }

    setIsLoadSummary(true)
    setIsLoadData(true)

    getPendaftarSummary(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setPendaftarSummary(payload)
          },
          onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
          }),
          onUnAuth: error => {
              
          },
          onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
          })
        })
      })
      .catch(error => {

      })
      .finally(() => {
        setIsLoadSummary(false)
      })

    getPendaftarDaily(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setPendaftarDaily(payload)
          },
          onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
          }),
          onUnAuth: error => {
              
          },
          onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
          })
        })
      })
      .catch(error => {
      })
      .finally(() => setIsLoadData(false))

  }, [startDate, untilDate, pageOpts.selectedOpt.value])

  useEffect(() => {
    document.body.style.backgroundColor =  isPhone(screenSize.width) ? 'white' : '#232745';

    dispatch(setActivePage('participant'))
  }, [screenSize.width])

  return (
    <div
      className="flex justify-center"
    >
      <div
        className={
          isPhone(screenSize.width) ? " w-full space-y-3  " : "w-[800px] pt-10 space-y-3"
        }
      >

        <Navbar />

        <div
          className="bg-white rounded-lg divide-y divide-gray-200 z-0"
        >
          <div
            className="p-3"
          >
            <div
              className="uppercase font-bold"
            >
              Data Pendaftar
            </div>
            <div
              className="italic"
              style={{
                fontSize: '14px'
              }}
            >
              Akun-akun yang mendaftar melalui link referal anda
            </div>
          </div>
          <div
            className="flex items-center space-x-2 p-3"
            style={{
              fontSize: '14px'
            }}
          >
            <div className="flex items-center">
              <div className="h-10 flex items-center px-1 border-t border-l border-b border-gray-400">
                <GoCalendar className="h-6 w-6"/>
              </div>
              <DatePicker 
                locale={id}
                className="border border-gray-400 p-1 w-28 h-10" 
                selected={startDate} 
                onChange={(date) => setStartDate(date)} 
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div>-</div>
            <div className="flex items-center">
              <div className="h-10 flex items-center px-1 border-t border-l border-b border-gray-400">
                <GoCalendar className="h-6 w-6"/>
              </div>
              <DatePicker
                className="border border-gray-400 p-1 w-28 h-10" 
                selected={untilDate} 
                onChange={(date) => setUntilDate(date)} 
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <div 
            className="pb-10"
          >
            <div
              className="pt-2 px-2 bg-gray-500"
            >
              {pageOpts.datas.map(p => (
                <button 
                  className={
                    "w-36 py-2 rounded-tl-md rounded-tr-md " +
                    (p.value === pageOpts.selectedOpt.value ? ' bg-white ' : ' bg-gray-300 ' )
                  }
                  onClick={() => setPageOpts({ ...pageOpts, selectedOpt: p })}
                >
                  {p.label}
                </button>
              ))}
            </div>
            <div className="py-5">
              {
                isLoadSummary ?
                  (<div className="flex justify-center">
                    <RiLoader5Fill className="animate-spin h-10 w-10 text-blue-500"/>
                  </div>)
                  :
                  pendaftarSummary ? 
                    (<div className={
                        "grid px-5" + 
                        (isPhone(screenSize.width) ? " grid-cols-2 gap-3 " : " grid-cols-3 gap-3 ")
                      }>
                      <div className="bg-blue-500 rounded-lg p-2 shadow-lg text-white">
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '18px' }}
                        >
                          No Media
                        </div>
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '20px' }}
                        >
                          {pendaftarSummary.rsNonMediaTotal}
                        </div>
                      </div>
                      <div className="bg-blue-500 rounded-lg p-2 shadow-lg text-white">
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '18px' }}
                        >
                          Facebook
                        </div>
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '20px' }}
                        >
                          {pendaftarSummary.rsFacebookTotal}
                        </div>
                      </div>
                      <div className="bg-blue-500 rounded-lg p-2 shadow-lg text-white">
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '18px' }}
                        >
                          Instagram
                        </div>
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '20px' }}
                        >
                          {pendaftarSummary.rsInstagramTotal}
                        </div>
                      </div>
                      <div className="bg-blue-500 rounded-lg p-2 shadow-lg text-white">
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '18px' }}
                        >
                          Instagram Story
                        </div>
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '20px' }}
                        >
                          {pendaftarSummary.rsInstagramStoryTotal}
                        </div>
                      </div>
                      <div className="bg-blue-500 rounded-lg p-2 shadow-lg text-white">
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '18px' }}
                        >
                          Whatsapp
                        </div>
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '20px' }}
                        >
                          {pendaftarSummary.rsWhatsappTotal}
                        </div>
                      </div>
                      <div className="bg-blue-500 rounded-lg p-2 shadow-lg text-white">
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '18px' }}
                        >
                          Email
                        </div>
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '20px' }}
                        >
                          {pendaftarSummary.rsEmailTotal}
                        </div>
                      </div>
                      <div className="bg-blue-500 rounded-lg p-2 shadow-lg text-white">
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '18px' }}
                        >
                          Tiktok
                        </div>
                        <div 
                          className="font-bold text-center" 
                          style={{ fontFamily: 'Nunito', fontSize: '20px' }}
                        >
                          {pendaftarSummary.rsTiktokTotal}
                        </div>
                      </div>
                    </div>)
                    :
                    (<></>)
              }
            </div>
            <div className="py-2">
              {
                isLoadData ?
                  (<div className="flex justify-center">
                    <RiLoader5Fill className="animate-spin h-10 w-10 text-blue-500"/>
                  </div>)
                  :
                  (<Line 
                    options={options} 
                    data={
                      buildData(
                        chartLabels.map(d => format(d, 'yyyy-MM-dd')), 
                        chartLabels.map(d => format(d, 'dd/MM/yy')),
                        pendaftarDaily.rdNonMedia,
                        pendaftarDaily.rdFacebook,
                        pendaftarDaily.rdInstagram,
                        pendaftarDaily.rdInstagramStory,
                        pendaftarDaily.rdWhatsapp,
                        pendaftarDaily.rdEmail,
                        pendaftarDaily.rdTiktok
                      )
                    } 
                  />)
              }
            </div>

          </div>

        </div>

      </div>
    </div>
  )
}

export default ParticipantPage