import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../app/GlobalSlice";
import Navbar from "../../components/Navbar";
import { handleResponse200 } from "../../utils/HttpUtils";
import { notifyError, notifySuccess } from "../../utils/Notification";
import { getAffLink } from "./Api";
import Select from "react-select"
import { isPhone } from "../../utils/PlatformHelper";

const HomePage = () => {
  const dispatch = useDispatch()

  const { 
    member,
    screenSize
  } = useSelector(state => state.global)

  const [affLink, setAffLink] = useState(null)

  const [isLoadLinks, setIsLoadLinks] = useState(false)

  const [mediaOpts, setMediaOpts] = useState({
    datas: [
      { label: "--", value: null },
      { label: "Facebook", value: 'facebook' },
      { label: "Instagram", value: 'instagram' },
      { label: "Instagram Story", value: 'instagram_story' },
      { label: "Whatsapp", value: 'whatsapp' },
      { label: "Email", value: 'email' },
      { label: "Tiktok", value: 'tiktok' }
    ],
    selectedOpt: { label: "--", value: null }
  })

  useEffect(() => {
    document.body.style.backgroundColor =  isPhone(screenSize.width) ? 'white' : '#232745';

    dispatch(setActivePage('dashboard'))
  }, [screenSize.width])

  useEffect(() => {
    setIsLoadLinks(true)

    getAffLink()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setAffLink(payload)
          },
          onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
          }),
          onUnAuth: error => {
              
          },
          onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
          })
        })
      })
      .catch(error => {})
      .finally(() => setIsLoadLinks(false))

  }, [])

  if (!member) {
    return null;
  }

  return (
    <div
      className="flex justify-center"
    >
      <div
        className={
          isPhone(screenSize.width) ? " w-full space-y-3  " : "w-[800px] pt-10 space-y-3"
        }
      >

        <Navbar />

        <div
          className="rounded-lg bg-white"
        >
          <div
            className="p-2 space-y-3"
          >
            <div
              className="rounded-lg bg-gray-200 p-3 space-y-2"
            >
              <div
                className="text-center uppercase font-bold"
              >
                Selamat Datang
              </div>
              <div
                className="text-center"
                style={{
                  fontSize: '14px'
                }}
              >
                Anda telah login dengan detail akun sebagai berikut
              </div>
              <div
                className="flex justify-center"
              >
                <div
                  className={
                    (isPhone(screenSize.width) ? " w-full " : " w-1/2 ") +
                    " p-3 space-y-2 px-5"
                  }
                  style={{
                    fontSize: '14px'
                  }}
                >
                  <div
                    className={
                      (isPhone(screenSize.width) ? "" : " flex space-x-5 ") +
                      ""
                    }
                  >
                    <div className="w-16 text-left">ID</div>
                    {!isPhone(screenSize.width) && (<div>:</div>)}
                    <div className="flex-1">{member.idAffiliater}</div>
                  </div>
                  <div
                    className={
                      (isPhone(screenSize.width) ? "" : " flex space-x-5 ") +
                      ""
                    }
                  >
                    <div className="w-16 text-left">Nama</div>
                    {!isPhone(screenSize.width) && (<div>:</div>)}
                    <div className="flex-1">{member.namaAffiliater}</div>
                  </div>
                  <div
                    className={
                      (isPhone(screenSize.width) ? "" : " flex space-x-5 ") +
                      ""
                    }
                  >
                    <div className="w-16 text-left">Email</div>
                    {!isPhone(screenSize.width) && (<div>:</div>)}
                    <div className="flex-1">{member.email}</div>
                  </div>
                  <div
                    className={
                      (isPhone(screenSize.width) ? "" : " flex space-x-5 ") +
                      ""
                    }
                  >
                    <div className="w-16 text-left">No. HP</div>
                    {!isPhone(screenSize.width) && (<div>:</div>)}
                    <div className="flex-1">+62 - {member.noHp}</div>
                  </div>
                </div>
              </div>

            </div>
            <div
              className="rounded-lg border p-3 space-y-2"
            >
              <div>
                <div
                  className="text-center uppercase font-bold"
                >
                  Link Affiliater
                </div>
                <div
                  className="italic text-center"
                  style={{
                    fontSize: '14px'
                  }}
                >
                  di bawah ini merupakan link affiliater anda
                </div>
              </div>
              {
                isLoadLinks ?
                  (<>
                  </>)
                  :
                  affLink ?
                    (
                      <div className="space-y-5">
                        <div className="space-y-1">
                          <div>
                            Media
                          </div>
                          <div 
                            className="flex"
                            style={{ fontSize: '14px' }}
                          >
                            <Select
                              value={mediaOpts.selectedOpt}
                              options={mediaOpts.datas}
                              className="w-full border border-gray-200 rounded-md"
                              onChange={value => setMediaOpts({ ...mediaOpts, selectedOpt: value })}
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <div className="space-y-1" style={{ fontSize: '14px' }}>
                            <div>Landing page URL</div>
                            <div className="italic" style={{ fontSize: '13px' }}>Link halaman landing page PMB</div>
                          </div>
                          <div
                            className="relative"
                          >
                            <input 
                              style={{
                                fontSize: '15px'
                              }}
                              className="w-full border rounded-lg p-1.5 px-2 bg-blue-100"
                              value={
                                mediaOpts.selectedOpt.value === 'facebook' ?
                                  affLink.facebookLink
                                  :
                                  mediaOpts.selectedOpt.value === 'instagram' ?
                                    affLink.instagramLink
                                    :
                                    mediaOpts.selectedOpt.value === 'instagram_story' ?
                                      affLink.instagramStoryLink
                                      :
                                      mediaOpts.selectedOpt.value === 'whatsapp' ?
                                        affLink.whatsappLink
                                        :
                                        mediaOpts.selectedOpt.value === 'email' ?
                                          affLink.emailLink
                                          :
                                          mediaOpts.selectedOpt.value === 'tiktok' ?
                                            affLink.tiktokLink
                                            :
                                            affLink.lpLink
                              }
                              readOnly
                            />
                            <div
                              className="absolute right-0 top-0 bottom-0 flex items-center"
                            >
                              <button
                                className="bg-green-600 text-white hover:bg-green-400 hover:shadow-lg rounded-md px-5 py-1 mr-1"
                                style={{
                                  fontSize: '14px'
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    mediaOpts.selectedOpt.value === 'facebook' ?
                                      affLink.facebookLink
                                      :
                                      mediaOpts.selectedOpt.value === 'instagram' ?
                                        affLink.instagramLink
                                        :
                                        mediaOpts.selectedOpt.value === 'instagram_story' ?
                                          affLink.instagramStoryLink
                                          :
                                          mediaOpts.selectedOpt.value === 'whatsapp' ?
                                            affLink.whatsappLink
                                            :
                                            mediaOpts.selectedOpt.value === 'email' ?
                                              affLink.emailLink
                                              :
                                              mediaOpts.selectedOpt.value === 'tiktok' ?
                                                affLink.tiktokLink
                                                :
                                                affLink.lpLink
                                  )
                                  notifySuccess('Berhasil salin link')
                                }}
                              >
                                Salin
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="space-y-2">
                          <div className="space-y-1" style={{ fontSize: '14px' }}>
                            <div>Registration page URL</div>
                            <div className="italic" style={{ fontSize: '13px' }}>Link halaman pendaftaran murid</div>
                          </div>
                          <div
                            className="relative"
                          >
                            <input 
                              style={{
                                fontSize: '15px'
                              }}
                              className="w-full border rounded-lg p-1.5 px-2 bg-blue-100"
                              value={
                                mediaOpts.selectedOpt.value === 'facebook' ?
                                  affLink.facebookCkLink
                                  :
                                  mediaOpts.selectedOpt.value === 'instagram' ?
                                    affLink.instagramCkLink
                                    :
                                    mediaOpts.selectedOpt.value === 'instagram_story' ?
                                      affLink.instagramStoryCkLink
                                      :
                                      mediaOpts.selectedOpt.value === 'whatsapp' ?
                                        affLink.whatsappCkLink
                                        :
                                        mediaOpts.selectedOpt.value === 'email' ?
                                          affLink.emailCkLink
                                          :
                                          mediaOpts.selectedOpt.value === 'tiktok' ?
                                            affLink.tiktokCkLink
                                            :
                                            affLink.ckLink
                              }
                              readOnly
                            />
                            <div
                              className="absolute right-0 top-0 bottom-0 flex items-center"
                            >
                              <button
                                className="bg-green-600 text-white hover:bg-green-400 hover:shadow-lg rounded-md px-5 py-1 mr-1"
                                style={{
                                  fontSize: '14px'
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    mediaOpts.selectedOpt.value === 'facebook' ?
                                      affLink.facebookCkLink
                                      :
                                      mediaOpts.selectedOpt.value === 'instagram' ?
                                        affLink.instagramCkLink
                                        :
                                        mediaOpts.selectedOpt.value === 'instagram_story' ?
                                          affLink.instagramStoryCkLink
                                          :
                                          mediaOpts.selectedOpt.value === 'whatsapp' ?
                                            affLink.whatsappCkLink
                                            :
                                            mediaOpts.selectedOpt.value === 'email' ?
                                              affLink.emailCkLink
                                              :
                                              mediaOpts.selectedOpt.value === 'tiktok' ?
                                                affLink.tiktokCkLink
                                                :
                                                affLink.ckLink
                                  )

                                  notifySuccess('Berhasil salin link')
                                }}
                              >
                                Salin
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    :
                    (<></>)
              }
            </div>
          </div> 
        </div>

      </div>

    </div>
  )
}

export default HomePage;