import { RiLoader5Fill } from "react-icons/ri";

const LoadScreenWrapper = ({
  isShow
}) => {

  if (!isShow) {
    return null;
  }

  return (
    <>
    <div
      className="fixed w-screen h-screen bg-black z-40 opacity-50"
      style={{
        zIndex: '98'
      }}
    />
    <div 
      className="fixed w-screen h-screen flex items-center justify-center z-50"
      style={{
        zIndex: '99'
      }}
    >
      <RiLoader5Fill 
        className="h-14 w-14 text-blue-500 animate-spin"
      />
    </div>
    </>
  )

}

export default LoadScreenWrapper;