import { useEffect, useState } from "react";
import Select from "react-select";
import { getProgram } from "../Api";

const PencairanComp = () => {

  const [isLoadProgram, setIsLoadProgram] = useState(false)

  const [programOpts, setProgramOpts] = useState({
    datas: [ { value: "program1", label: "Program1" }, { value: "program2", label: "Program2" } ],
    selectedOpt: null
  })

  useEffect(() => {
    getProgram()
      .then(res => {
        if (res.success) {
          setProgramOpts({ 
            ...programOpts, 
            datas: res.payload.map(pr => ({ label: pr.namaProgram, value: pr.idProgram })) 
          })
        }
      })
      .catch(error => {
        console.log(error)
      })

  }, [])

  useEffect(() => {
    if (programOpts.selectedOpt == null) return;

    console.log('get pencairan komisi affiliater program');

  }, [programOpts.selectedOpt])

  return (
    <div>
      <div className="p-3">
        <div className="py-2">
          <Select
            value={programOpts.selectedOpt}
            options={programOpts.datas} 
            className="w-full border border-gray-200 rounded-md"
            onChange={value => setProgramOpts({ ...programOpts, selectedOpt: value })}
          />
        </div>
        <div>
          <table className="w-full table-fixed">
            <thead className="border-b bg-gray-200 font-medium dark:border-neutral-500">
              <tr>
                <th className="w-2/3 font-light py-1">
                  Catatan
                </th>
                <th className="w-1/3 font-light py-1">
                  Pencairan
                </th>
              </tr>
            </thead>
            <tbody>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PencairanComp;