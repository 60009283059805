import axios from "axios"

export const getProgram = async () => {
  const finalUrl =
      window._HOST + "public/program"

  const response =
      await axios.get(finalUrl)

  return response.data
}

export const getAffIncome = async () => {
  const finalUrl =
      window._HOST + "affiliater/income"

  const response =
      await axios.get(finalUrl)

  return response.data
}