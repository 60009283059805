import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsCheckCircleFill, BsFillShieldLockFill } from "react-icons/bs"
import { RiLoader5Fill } from "react-icons/ri"
import { forgetPassword } from "./apis/Api";
import { handleResponse200 } from "../../utils/HttpUtils";
import { notifyError } from "../../utils/Notification";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";

const ForgetPasswordPage = () => {

  const [form, setForm] = useState({
    email: ''
  });

  const [isProcessing, setIsProcessing] = useState(false)
  const [isSuccess, setIsSuccess] = useState(null)

  useEffect(() => {
    document.body.style.backgroundColor = '#232745';
  }, [])

  function onForgetPassword() {

    const errors = [];

    if (form.email === '') {
      errors.push('Email tidak boleh kosong')
    }

    if (errors.length > 0) {
      return;
    }

    setIsProcessing(true)

    forgetPassword(form.email)  
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setIsSuccess(true)
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
              
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {

      })
      .finally(() => setIsProcessing(false))

  }

  return (
    <div
      className="w-screen h-screen"
      style={{
        backgroundColor: '#232745'
      }}
    >
      <div
        className="py-10 flex justify-center"
      >

        <div
          className="relative rounded-sm flex"
          style={{
            width: '800px',
            height: '800px'
          }}
        >

          {isProcessing && (
            <div 
              className="absolute top-0 left-0 right-0 bottom-0 z-10"
            />
          )}
              (<>
                <div
                  className="flex-1 bg-white"
                >
                  {
                    isSuccess === null ?
                      (<div
                        className="flex items-center w-full h-full"
                      >
                        <div
                          className="px-10 overflow-y-auto w-full"
                          style={{
                            // height: '400px',
                            marginTop: '-200px'
                          }}
                        >
                          <div
                            className="py-5 text-center space-y-8"
                            style={{
                              fontSize: '14px'
                            }}
                          >
                            <div
                              className="flex justify-center"
                            >
                              <BsFillShieldLockFill className="h-14 w-14 text-green-800"/>
                            </div>
                            <div
                              className="space-y-3"
                            >
                              <div 
                                className="font-bold"
                                style={{
                                  fontSize: '20px'
                                }}
                              >
                                Lupa kata Sandi?
                              </div>
                              <div
                                className="px-16"
                              >
                                Masukan email yang terdaftar di sistem PMB kami dan kami akan mengirimkan email untuk mereset kata sandi akun anda.
                              </div>
    
                            </div>
                          </div>
                          <div
                            className="flex justify-center"
                          >
                            <div
                              className="w-2/3 space-y-5"
                            >
                              <div
                                className="space-y-1"
                              >
                                <div
                                  className=""
                                  style={{
                                    fontSize: '12px'
                                  }}
                                >
                                  Email
                                </div>
                                <div>
                                  <input 
                                    className="w-full p-3 border border-gray-300 rounded-md outline-green-300"
                                    placeholder="Email"
                                    style={{
                                      fontSize: '14px'
                                    }}
                                    value={form.email}
                                    onChange={e => setForm({ ...form, email: e.target.value })}
                                  />
                                </div>
                              </div>
                              <div>
                                <button
                                  className="w-full py-2 flex justify-center bg-green-800 hover:bg-green-700 text-white rounded-md"
                                  style={{
                                    fontSize: '14px'
                                  }}
                                  onClick={onForgetPassword}
                                >
                                  {
                                    isProcessing ?
                                      <RiLoader5Fill className="h-5 w-5 animate-spin"/>
                                      :
                                      'RESET EMAIL'
                                  }
                                </button>
                              </div>
                              <div>
                                <div>
                                  <Link to={'/login'}>
                                    <button
                                      className="w-full py-2 text-center bg-blue-700 hover:bg-blue-500 text-white rounded-md"
                                      style={{
                                        fontSize: '14px'
                                      }}
                                    >
                                      Halaman Login
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
    
                          </div>
                        </div>
                        
                      </div>)
                      :
                      isSuccess ?
                        (<div
                            className="space-y-5 flex flex-col justify-center h-full"
                          >
                            <div
                              className="flex justify-center items-center space-x-3"
                            >
                              <BsCheckCircleFill className="h-20 w-20 text-green-500"/>
                              <BiMailSend className="h-20 w-20 text-green-500"/>
                            </div>
                            <div
                              className="text-center"
                            >
                              <div>Permintaan berhasil diproses</div> 
                              <div>Silahkan klik tombol di bawah untuk login.</div>
                              <div
                                className="flex justify-center py-5"
                              >
                                <Link
                                  to={'/login'}
                                >
                                <button
                                  className="py-2 px-10 rounded-sm bg-blue-700 hover:bg-blue-500 text-white"
                                  style={{
                                    fontSize: '14px'
                                  }}
                                >
                                  Login
                                </button>
                                </Link>
                              </div>
                            </div>

                          </div>)
                        :
                        (<div
                          className="py-20 space-y-5 flex flex-col justify-center h-full"
                        >
                          <div
                            className="flex justify-center"
                          >
                            <AiFillCloseCircle className="h-20 w-20 text-red-500"/>
                          </div>
                          <div
                            className="text-center"
                          >
                            <div>Permintaan gagal diproses</div> 
                            <div>Kontak kami untuk informasi lebih lanjut</div>
                          </div>

                        </div>)

                  }
                  

                </div>
              </>)

          
        </div>


      </div>

    </div>
  )

}

export default ForgetPasswordPage;